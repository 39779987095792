var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-style",class:[{'not-found-show':!_vm.$root.token}],style:([{'background-color':_vm.$store.state.corpInfo.darkTheme?'black':'#E2E6EF'}])},[(_vm.$root.token)?_c('div',{staticClass:"body-container d-flex justify-center align-center",style:({
      'background': `linear-gradient(
        to bottom,
        ${_vm.$store.state.corpInfo.darkTheme ? 
        '#000000 0%, var(--color-bg-others) 100%':'#E2E6EF 0%, var(--color-bg-others) 100%'}
      )`})},[_vm._m(0),(_vm.$store.getters['auth/getUserDataS'])?_c('MyProfile',{staticClass:"mx-auto d-flex justify-center"}):_vm._e()],1):_c('div',{staticClass:"no-found-style"},[_c('NotFound')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stars"},[_c('div',{attrs:{"id":"stars"}}),_c('div',{attrs:{"id":"stars2"}}),_c('div',{attrs:{"id":"stars3"}})])
}]

export { render, staticRenderFns }