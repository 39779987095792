<template>
  <div class="ma-0 mt-0 pa-0">
    <v-card elevation="0" class="ma-0 mt-0 px-6" max-width="1000px" 
      :color="$store.state.corpInfo.darkTheme ?'#323232BB':'#FFFFFF96'">
      <div>
        <UserInfoForm
          ref="FormClient"
          @ValidationUserForm="ValidationUserForm"
          :editInfo="editUserData"
          @editUserInfo="editUserInfo"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import UserInfoForm from "./UserInfoForm.vue";

export default {
  name: "MyProfile",
  components: {
    UserInfoForm
  },
  data() {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      editUserData : false,
      showPreview : true,
      fileEmpty : false
    };
  },
  methods: {
    save: function () {
      this.$refs.FormClient.Save();
    },
    ValidationUserForm: function (val) {
      if (val === true) {
        this.showPreview = true;
      }
    },
    editUserInfo(val) {
      this.editUserData = true;
      this.showPreview = false;
      if(val === 1) {
        this.fileEmpty = true;
      }
    },
  }
};
</script>

<style scoped>

</style>