<template>
  <div style="background-color:transparent" id="Fomulario" align=center>
    <v-card color="transparent" v-if="form.nombre == null" width="400" height="328" elevation="0">
      <LoadingA/>
    </v-card>    
    <v-card
      v-else 
      elevation="0"
      style="background-color: transparent">
      <!-- <v-card-title class="align-right">
        <v-spacer></v-spacer>
        <v-btn :disabled="editInformation" text dark outlined class="d-block mx-auto" @click="editInformation = true" color="var(--color-primary)">
          {{ $t("actions.change") }}</v-btn>
      </v-card-title> -->
      <v-card-title class="text-uppercase font-weight-bold d-flex justify-center align-center"
        :class="[{'white--text': $store.state.corpInfo.darkTheme}, {'black--text': !$store.state.corpInfo.darkTheme}]">
        {{ $t("navigation.account.myProfile") }}
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form v-model="formuValidator" ref="FormularioPago" class="py-2">
          <v-row>
            <v-col cols="12" class="mt-3 mb-4">
              <h4 class="text-uppercase font-weight-medium text-left"
                :class="[{'white--text': $store.state.corpInfo.darkTheme}, {'black--text': !$store.state.corpInfo.darkTheme}]">
                {{ $t("navigation.accountData") }}
              </h4>
            </v-col>            
            <v-col cols="12" md="6" class="pb-0 mb-n2">
              <v-text-field
                color="var(--color-primary)"
                :dark="$store.state.corpInfo.darkTheme"
                :disabled="!editInformation"
                v-model="form.nombre"
                :label="$t('login.name')+'(s)'"
                :placeholder="$t('login.PutName')"
                outlined
                required
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 mb-n2">
              <v-text-field
                color="var(--color-primary)"
                :dark="$store.state.corpInfo.darkTheme"
                :disabled="!editInformation"
                v-model="form.apellido"
                :label="$t('login.lastName')+'(s)'"
                :placeholder="$t('login.PutLastName')"
                outlined
                required
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 mb-n2">
              <v-text-field
                color="var(--color-primary)"
                :dark="$store.state.corpInfo.darkTheme"
                v-model="form.correo"
                disabled
                :label="$t('login.email')"
                placeholder="example@gmail.com"
                outlined
                required
                :rules="[rules.required, rules.email]"
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 mb-n2">
              <v-text-field
                color="var(--color-primary)"
                :dark="$store.state.corpInfo.darkTheme"
                :disabled="!editInformation"
                v-model="form.celular"
                :label="$t('login.phone')"
                placeholder="000 00 00 000"
                v-mask="'### ## ## ###'"
                outlined
                required
                :rules="[rules.required, rules.phone]"
              />
            </v-col>            
            <v-col cols="12" class="mt-3 mb-4">
              <h4 class="text-uppercase font-weight-medium text-left"
              :class="[{'white--text': $store.state.corpInfo.darkTheme}, {'black--text': !$store.state.corpInfo.darkTheme}]">
                {{ $t("navigation.accountAddressData") }}
              </h4>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 mb-n2">
              <v-text-field
                color="var(--color-primary)"
                :dark="$store.state.corpInfo.darkTheme"
                :disabled="!editInformation"
                v-model="form.calle"
                :label="$t('login.address')"
                :placeholder="$t('login.street')+' ##, ####'"
                outlined
                required
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 mb-n2">
              <v-text-field
                color="var(--color-primary)"
                :dark="$store.state.corpInfo.darkTheme"
                :disabled="!editInformation"
                v-model="form.cp"
                :label="$t('login.postalCode')"
                placeholder="#####"
                outlined
                required
                :rules="[rules.required]"
              />
            </v-col>
            <v-row class="px-3 py-2">
              <v-col cols="12" md="4" class="pb-0 mb-n2">
                <v-select
                  color="var(--color-primary)"
                  :dark="$store.state.corpInfo.darkTheme"
                  :disabled="!editInformation"
                  v-model="location.pais"
                  :items="listOfCountries.countries"
                  item-text="name_es"
                  item-value="code"          
                  :label="$t('login.country')"
                  :rules="[rules.required, rules.exists]"
                  @change="LoadEstados"
                  @keydown="changeLocation(true)"
                  outlined>
                </v-select>
              </v-col>
              <v-col cols="12" md="4" class="pb-0 mb-n2">
                <v-select
                  color="var(--color-primary)"
                  :dark="$store.state.corpInfo.darkTheme"
                  :disabled="!location.pais"
                  v-model="location.estado"
                  :items="estados"
                  :label="$t('login.state')"
                  :rules="[rules.required, rules.exists]"
                  @change="LoadCiudades"
                  @keydown="changeLocation(false)"
                  outlined>
                </v-select>
              </v-col>
              <v-col cols="12" md="4" class="pb-0 mb-n2">
                <v-text-field
                  color="var(--color-primary)"
                  :dark="$store.state.corpInfo.darkTheme"
                  :disabled="!location.estado"
                  v-model="form.ciudad"
                  :label="$t('login.city')"
                  outlined
                  required
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-row> 
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="comparisonForm === JSON.stringify(form) && !fileEmpty" 
          class="d-block mx-auto" 
          color="var(--color-primary)" 
          dark
          @click="Save()">{{ $t("actions.save") }}</v-btn>
        <v-btn :disabled="comparisonForm === JSON.stringify(form) && !fileEmpty" 
          class="d-block mx-auto ml-5"
          color="var(--color-primary)"
          text
          dark
          @click="resetEditing">{{ $t("actions.cancel") }}</v-btn>
      </v-card-actions>
    </v-card>    
  </div>
</template>

<script>
import LoadingA from "../Tools/LoadingAnimation.vue";
import ListOfCountries from "../../services/ListOfCountries.json";

export default {
  name: "UserInfoForm",
  components: {
    LoadingA,
  },
  data() {
    return {
      comparisonForm: {},
      flipNumber: 0,
      listOfCountries: {"countries": []},
      terminos: false,
      estados: [],
      ciudades: [],
      formuValidator: true,
      rules: {
        required: (v) => !!v || this.$t("messages.validation.required"),
        email: (v) =>
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/.test(v) ||
          this.$t("messages.validation.isEmail"),
        card: (v) =>
          /([\d]{4}) ([\d]{4}) ([\d]{4}) ([\d]{4})/.test(v) ||
          this.$t("messages.validation.validCard"),
        phone: (v) =>
          /([\d]{3}) ([\d]{2}) ([\d]{2}) ([\d]{3})/.test(v) ||
          this.$t("messages.validation.isPhone"),
        exists: (value) => {
          if (!value) return this.$t("messages.validation.validLocation");
          // if (value != null && typeof value === "object") {
          //   if (value.ciudadnombre) return true;
          //   if (value.ciudaddistrito) return true;
          //   if (value.code) return true;
          //   return this.$t("messages.validation.validLocation");
          // }
          return true;
        },          
      },
      location: {
        pais: null,
        estado: null,
        ciudad: null
      },
      showViewInfo: true,
      formPreview : {},
      fileEmpty: false,
      form: {},
      editInformation: true
    };
  },
  created() {
    this.form = {...this.$store.state.auth.userData}
  },
  mounted() { 
    this.form = {...this.$store.state.auth.userData}
    this.comparisonForm = JSON.stringify({...this.$store.state.auth.userData})
    if(this.form.pais === null || this.form.ciudad === null) {
      this.fileEmpty = true;
    }
    this.begin() 
    this.loadCityAndState()
  },
  methods: {
    begin() {
      this.listOfCountries = ListOfCountries;
      this.form = {...this.$store.state.auth.userData}
    },
    loadCityAndState: function () {
      if (this.form.pais) {
        this.LoadEstados();
        if (this.form.estado && this.form.ciudad) {
          this.LoadCiudades();
        }
      }
      if(!this.form.pais || !this.form.ciudad) {
        this.fileEmpty = true;
      }
      if(this.$refs.FormularioPago) this.$refs.FormularioPago.resetValidation();
    },
    Save() {
      if (this.$refs.FormularioPago.validate()) {
        if(this.form.nombre) {
          if (this.comparisonForm !== JSON.stringify(this.form)) {
            this.$root.post("/ServiceClient/UpdateUserData", {
              form : this.form
            }).then((response) => {
              let info = response.data;
              if (info.success === "TRUE") {
                this.fileEmpty = false;
                this.$store.dispatch('auth/updateUserInfo', this.form)
                this.$root.swalAlert(
                  "success",
                  "",
                  this.$t("messages.UpdateInfoUser")
                );                
                this.comparisonForm = JSON.stringify({...this.form})
              }
            }).finally(() => {
              // this.editInformation = false
            });
          } else {          
            this.resetEditing();
          }
        }        
      }
    },
    LoadEstados(val) {
      this.form.pais = val ? val : this.form.pais;
      let country = ListOfCountries.countries.find(value => value.code == this.form.pais);
      this.location.pais = country;
      if(val) {
        this.changeLocation(true);
      }  
      this.$root.post("/ServiceClient/GetEstados", {
        code: this.form.pais
      }).then((response) => {
        let info = response.data;
        if (info.success === "TRUE") {
          this.form.pais = val ? val : this.form.pais;
          this.estados = info.estados;
        }
      });
    },
    LoadCiudades(val) {
      if(val) {
        this.changeLocation(false);  
      }
      this.form.estado = val ? val : this.form.estado;
      this.location.estado = this.form.estado
    },
    cityChange(val) {
      this.form.ciudad = val ? val : this.form.ciudad;
      if(!val) {
        this.location.ciudad = this.form.ciudad
      }
    }, 
    resetEditing () {
      this.form = {...JSON.parse(this.comparisonForm) }
      let country = ListOfCountries.countries.find(value => value.code == this.form.pais);
      this.location.pais = country;
      this.LoadEstados(false)
      this.location.estado = this.form.estado
      this.location.ciudad = this.form.ciudad
    },
    changeLocation(country) {
      if(country === true) {
        this.location.estado = null;
        this.form.estado = null;
      } 
      this.location.ciudad = null;
      this.form.ciudad = null;
    }
  },
};
</script>

<style scoped>
  .text-showViewInfo-data {
    color: #b4b4b4 !important;
  }
  .text-showViewInfo-titles {
    color: var(--color-primary-light) !important;
  }
</style>